import {useParams} from 'react-router-dom';

import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useEffect, useRef, useState} from 'react';

import {AttachmentService} from '@services/attachment';
import {CommentService} from '@services/comment';

import {FilterButton} from '@Common/Buttons';
import CommentEditor from '@Common/CommentEditor';
import {CrossIcon, PlusIcon} from '@Common/Images';
import {StyledLink} from '@Common/Link';
import {Colors, TextHeader, TextParagraph} from '@Common/Text';

import {IPostAttachment} from 'types/state/Attachment';
import {IPostComment} from 'types/state/Comment';

import {ButtonWrapper, CloseButton, Documents, FileInput, ModalWrapper, Overlay} from './styles';

interface ModalProps {
  object_id: number;
  isOpen: boolean;
  onClose: () => void;
  content_type: number;
  action: string;
}

const CommentModal = ({isOpen, onClose, object_id, action, content_type}: ModalProps) => {
  const queryClient = useQueryClient();
  const pageId = Number(useParams().id);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState(false);
  const [comment, setComment] = useState<string | null>(null);
  // const [commentId, setCommentId] = useState<null | number>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  useEffect(() => {
    setError(false);
    setComment(null);
    setSelectedFile(null);
  }, [isOpen]);

  const mutationAttachment = useMutation({
    mutationFn: (data: IPostAttachment) => AttachmentService.create(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(['attachments']);
    },
  });

  const mutation = useMutation({
    mutationFn: (data: IPostComment) => CommentService.create(data),
    onSuccess: responce => {
      void queryClient.invalidateQueries(['comment', object_id]);
      void queryClient.invalidateQueries(['comments', 'router', object_id]);
      void queryClient.invalidateQueries(['comments', 'stage', object_id]);
      void queryClient.invalidateQueries(['comments', pageId]);
      void queryClient.invalidateQueries(['projectStepList', pageId]);
      // setCommentId(responce.data.id);

      if (selectedFile) {
        mutationAttachment.mutate({
          content_type: 27,
          object_id: responce.data.id,

          attachment: selectedFile,
        });
      }
    },
  });

  const createComment = async () => {
    if (comment) {
      await mutation.mutateAsync({
        content_type: content_type,
        object_id: object_id,
        text: comment,
        action: action,
      });
      onClose();
    } else {
      setError(true);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedFile(file);
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Overlay onClick={onClose}>
        <ModalWrapper
          onClick={e => {
            e.stopPropagation();
          }}>
          <CloseButton onClick={onClose}>
            <CrossIcon />
          </CloseButton>
          <TextHeader>Добавление комментария</TextHeader>

          <CommentEditor height="250px" value={comment} setValue={setComment} />
          <Documents
            $isDragging={isDragging}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={openFileSelector}>
            {selectedFile ? (
              <StyledLink href={URL.createObjectURL(selectedFile)}>{selectedFile.name}</StyledLink>
            ) : (
              <TextParagraph color={Colors.textDark}>Выбрать файл</TextParagraph>
            )}

            <PlusIcon />
          </Documents>
          <FileInput ref={fileInputRef} onChange={handleFileChange} />

          {error && <TextParagraph color={Colors.red}>Комментарий не добавлен</TextParagraph>}

          <ButtonWrapper>
            <FilterButton isBlue={false} title={'Отправить'} action={createComment} />
          </ButtonWrapper>
        </ModalWrapper>
      </Overlay>
    </>
  );
};

export default CommentModal;
