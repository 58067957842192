import styled from 'styled-components';

import {Colors, TextParagraph, TextSmall} from 'components/Common/Text';
import React, {FC, useEffect, useRef} from 'react';
import {NumericFormat} from 'react-number-format';

const StyledTextareaInput = styled.textarea<{$height?: number | null}>`
  color: var(--grey, #51575f);
  font-size: 16px;
  border: 0;
  outline: 0;
  width: 100%;

  resize: none;
  overflow-y: hidden;
  min-height: 30px;
  height: ${({$height}) => ($height ? `${$height}px` : '30px')};
`;

const StyledTextInput = styled.input`
  color: var(--grey, #51575f);
  font-size: 16px;
  border: 0;
  outline: 0;
  width: 100%;

  resize: none;
  overflow-y: hidden;
  min-height: 30px;
  /* height: 30px; */
`;

const StyledInputWrapper = styled.div<{$isNotField?: boolean; $width?: string}>`
  display: flex;
  padding: 5px 5px;
  align-items: center;
  width: ${({$width}) => $width || '100%'};
  gap: 10px;
  border-radius: 10px;
  border: 1px solid ${({theme, $isNotField}) => ($isNotField ? 'red' : theme.colors.greyCircuit)};
  background: #fff;

  height: auto;
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

interface ICommonProps {
  height?: number | null;
  width?: string;
  setHeight?: (height: number) => void;
  isNotField?: boolean;
  description?: string;
  errorMessage?: string;
  isTextarea?: boolean;
  autoFocus?: boolean;
  isMoney?: boolean;
  limit?: number;
  regExp?: RegExp;
  isCount?: boolean;
}

interface ITextInputWithoutRegister extends ICommonProps {
  value?: string | null;
  onChange: (event: any) => void;
  register?: never;
}

interface ITextInputWithRegister extends ICommonProps {
  value?: never;
  onChange?: never;
  register: any;
}

type ITextInput = ITextInputWithoutRegister | ITextInputWithRegister;

const TextInput: FC<ITextInput> = ({
  value,
  onChange,
  height,
  setHeight,
  isNotField,
  register,
  description,
  errorMessage,
  isTextarea = true,
  width,
  autoFocus = false,
  isMoney,
  limit,
  regExp,
  isCount,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const val = value ? {value: value} : {};
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.selectionStart = textarea.value.length;
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (setHeight) setHeight(textarea.scrollHeight);
    }
  }, [setHeight]);

  const handleTextareaInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (setHeight) setHeight(textarea.scrollHeight);
    }
    if (regExp) e.target.value = e.target.value.replace(regExp, '');
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <StyledFieldWrapper>
      <StyledInputWrapper $width={width} $isNotField={isNotField}>
        {isTextarea ? (
          <StyledTextareaInput
            $height={height}
            {...val}
            ref={onChange ? textareaRef : register.ref}
            onChange={onChange ? handleTextareaInput : register.onChange}
            autoFocus={register ? false : autoFocus}
            {...register}
            maxLength={limit}
          />
        ) : isMoney && onChange ? (
          <NumericFormat
            customInput={StyledTextInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
            value={value}
            thousandSeparator=" "
            suffix=" руб."
          />
        ) : (
          <StyledTextInput
            {...val}
            autoFocus={register ? false : autoFocus}
            {...register}
            autoComplete="on"
            maxLength={limit}
          />
        )}
      </StyledInputWrapper>
      {errorMessage && <TextParagraph color={Colors.accentBlue}>{errorMessage}</TextParagraph>}
      {description && <TextSmall>{description}</TextSmall>}
      {isCount && <TextSmall>Количество символов: {value?.length || 0}</TextSmall>}
    </StyledFieldWrapper>
  );
};

export default TextInput;
